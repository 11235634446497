var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "app-container MessageManagement" },
    [
      _vm.course != 1
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-header",
                { staticStyle: { height: "auto" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { margin: "0px 0px" },
                      attrs: { gutter: 24 },
                    },
                    [_c("Head", { attrs: { name: _vm.title } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit("newly", "")
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "tb",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          "tooltip-effect": "dark",
                          border: "",
                          "header-cell-style": {
                            background: "#F9F9F9",
                            color: "#222222",
                            textAlign: "center",
                          },
                          "cell-style": { textAlign: "center" },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "所属部门" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [_vm._v(_vm._s(scope.row.sname))]
                                },
                              },
                            ],
                            null,
                            false,
                            3867036137
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "等级名称" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [_vm._v(_vm._s(scope.row.title))]
                                },
                              },
                            ],
                            null,
                            false,
                            1628289981
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "部门权重" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(scope.row.structure_weight) + "%"
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1224097561
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "每日分配上限" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(_vm._s(scope.row.everyday_max)),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3606746647
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "未回访上限" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(_vm._s(scope.row.not_visit_max)),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3103830733
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "操作", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(
                                              "edit",
                                              scope.row.level_config_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1678556046
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("pagination", {
                      attrs: {
                        "current-page": _vm.query.page,
                        totalPage: _vm.query.total,
                        pageSize: _vm.query.pagesize,
                      },
                      on: {
                        handleSizeChange: _vm.handleSizeChange,
                        handleCurrentChange: _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        title: _vm.vals,
                        visible: _vm.dialogWeight,
                        width: "800px",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogWeight = $event
                        },
                        close: _vm.cancel,
                      },
                    },
                    [
                      this.tips
                        ? [
                            _c("div", { staticClass: "tips" }, [
                              _c("span", [_vm._v("配置修改后第二天生效")]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "等级名称", prop: "title" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择等级名称",
                                    disabled: _vm.disabled,
                                  },
                                  on: { change: _vm.levelChange },
                                  model: {
                                    value: _vm.ruleForm.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "title", $$v)
                                    },
                                    expression: "ruleForm.title",
                                  },
                                },
                                _vm._l(_vm.levels, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "部门权重",
                                prop: "structure_weight",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.ruleForm.structure_weight,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "structure_weight",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.structure_weight",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("% (" + _vm._s(_vm.range) + ")"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "每日分配上限",
                                prop: "everyday_max",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { type: "number", max: 20 },
                                  model: {
                                    value: _vm.ruleForm.everyday_max,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "everyday_max",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.everyday_max",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("(≤20)"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "未回访上限",
                                prop: "not_visit_max",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  on: {
                                    input: function ($event) {
                                      return _vm.getNume(
                                        _vm.ruleForm.not_visit_max
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.not_visit_max,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "not_visit_max",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.not_visit_max",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("(1-20之间)"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          staticStyle: { "text-align": "right" },
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialogWeight = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading.fullscreen.lock",
                                  value: _vm.fullscreenLoading,
                                  expression: "fullscreenLoading",
                                  modifiers: { fullscreen: true, lock: true },
                                },
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.createData("ruleForm")
                                },
                              },
                            },
                            [_vm._v("确认")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "boxImg",
              staticStyle: { width: "100%", padding: "20px" },
            },
            [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }