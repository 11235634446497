import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { levelConfigList, levelConfigAdd, levelConfigRead, levelConfigUpdate, levelConfigDelete } from '@/api/ceshi_xiugai/popularize';
export default {
  data: function data() {
    return {
      radio: 1,
      title: '权重配置',
      value: '',
      tableData: [],
      total: 0,
      visdisplay: false,
      type: 0,
      status: '',
      vals: '',
      dialogWeight: false,
      configureNum: 100,
      fullscreenLoading: false,
      loading: true,
      disabled: false,
      range: '10-30之间',
      levels: [{
        value: 'A',
        label: 'A'
      }, {
        value: 'B',
        label: 'B'
      }, {
        value: 'C',
        label: 'C'
      }],
      ruleForm: {
        id: '',
        title: '',
        structure_weight: '',
        everyday_max: '',
        not_visit_max: ''
        // remark: ''
      },
      rules: {
        title: [{
          required: true,
          message: '请选择等级名称',
          trigger: 'change'
        }],
        structure_weight: [{
          required: true,
          message: '请输入部门权重',
          trigger: 'blur'
        }],
        everyday_max: [{
          required: true,
          message: '请输入每日分配上限',
          trigger: 'blur'
        }],
        not_visit_max: [{
          required: true,
          message: '请输入未回访上限',
          trigger: 'blur'
        }]
      },
      tips: false,
      structure_weight: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      // NumberEs: 2,
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false
      // 系统教程
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    confirm: function confirm() {
      this.$emit('onConfirm');
    },
    cancel: function cancel() {
      this.dialogWeight = false;
      this.ruleForm.remark = '';
      this.$refs.ruleForm.resetFields();
    },
    GetList: function GetList(page, pagesize) {
      var _this = this;
      this.loading = true;
      levelConfigList({
        page: String(page),
        pagesize: String(pagesize)
      }).then(function (respomse) {
        _this.loading = false;
        _this.tableData = respomse.data.data;
        _this.configureNum = respomse.data.already_total;
        _this.query.total = respomse.data.total;
      });
    },
    //
    getNum: function getNum(value) {
      this.ruleForm.structure_weight = value.replace(/[^\d]/g, '');
    },
    getNums: function getNums(value) {
      this.ruleForm.everyday_max = value.replace(/[^0-9]/g, '');
    },
    getNume: function getNume(value) {
      this.ruleForm.not_visit_max = value.replace(/[^0-9]/g, '');
    },
    getEg: function getEg(value) {
      this.ruleForm.title = value.replace(/[^a-zA-Z]/g, '');
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this2 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this2.GetList(_this2.query.page, _this2.query.pagesize);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this3.GetList(_this3.query.page, _this3.query.pagesize);
      });
    },
    levelChange: function levelChange(val) {
      if (val == 'A') {
        this.range = '10-30之间';
      } else if (val == 'B') {
        this.range = '40-60之间';
      } else if (val == 'C') {
        this.range = '10-20之间';
      }
    },
    handleEdit: function handleEdit(val, key) {
      var _this4 = this;
      //  新增/新增子栏目/编辑弹窗
      if (val == 'edit') {
        this.vals = '编辑等级权重';
        this.dialogWeight = true;
        this.tips = true;
        this.disabled = true;
        levelConfigRead({
          level_config_id: String(key)
        }).then(function (respomse) {
          var data = respomse.data;
          _this4.ruleForm.id = data.level_config_id;
          _this4.ruleForm.title = data.title;
          if (_this4.ruleForm.title == 'A') {
            _this4.range = '10-30之间';
          } else if (_this4.ruleForm.title == 'B') {
            _this4.range = '40-60之间';
          } else if (_this4.ruleForm.title == 'C') {
            _this4.range = '10-20之间';
          }
          _this4.ruleForm.structure_weight = data.structure_weight;
          _this4.ruleForm.everyday_max = data.everyday_max;
          _this4.ruleForm.not_visit_max = data.not_visit_max;
          _this4.ruleForm.remark = data.remark;
          _this4.structure_weight = data.structure_weight;
        }).catch(function () {});
      } else if (val == 'newly') {
        this.vals = '新增等级权重';
        this.dialogWeight = true;
        this.disabled = false;
      }
    },
    delLevel: function delLevel(id) {
      var _this5 = this;
      levelConfigDelete({
        level_config_id: id
      }).then(function (respomse) {
        _this5.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success',
          duration: 2000
        });
        _this5.GetList(_this5.query.page, _this5.query.pagesize);
      });
    },
    createData: function createData(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            id: Number(_this6.ruleForm.id),
            title: _this6.ruleForm.title,
            structure_weight: Number(_this6.ruleForm.structure_weight),
            everyday_max: Number(_this6.ruleForm.everyday_max),
            not_visit_max: Number(_this6.ruleForm.not_visit_max)
          };
          if (_this6.vals == '新增等级权重') {
            levelConfigAdd(data).then(function (respomse) {
              _this6.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success',
                duration: 2000
              });
              _this6.dialogWeight = false;
              _this6.GetList(_this6.query.page, _this6.query.pagesize);
            }).catch(function () {});
          } else if (_this6.vals == '编辑等级权重') {
            // this.fullscreenLoading = true;
            if (_this6.structure_weight != _this6.ruleForm.structure_weight) {
              _this6.$confirm('修改部门权重会清空本部门所有人员级别，需重新配置，确定修改？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(function () {
                levelConfigUpdate(data).then(function (respomse) {
                  _this6.$notify({
                    title: '成功',
                    message: '编辑成功',
                    type: 'success',
                    duration: 2000
                  });
                  _this6.fullscreenLoading = false;
                  _this6.dialogWeight = false;
                  _this6.GetList(_this6.query.page, _this6.query.pagesize);
                  _this6.ruleForm.id = '';
                }).catch(function () {});
              }).catch(function () {});
            } else {
              levelConfigUpdate(data).then(function (respomse) {
                _this6.$notify({
                  title: '成功',
                  message: '编辑成功',
                  type: 'success',
                  duration: 2000
                });
                _this6.fullscreenLoading = false;
                _this6.dialogWeight = false;
                _this6.GetList(_this6.query.page, _this6.query.pagesize);
                _this6.ruleForm.id = '';
              }).catch(function () {});
            }
          }
        } else {
          return false;
        }
      });
    }
  }
};